.wrapper {
    max-width: 960px;
    width: 100%;
    margin: 30px auto;
    transform: scale(0.8);
}

.landing-page {
    max-width: 960px;
    height: 475px;
    margin: 0;
    box-shadow: 0px 0px 8px 1px #ccc;
    background: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper svg {
    width: 100%;
    max-height: 225px;
    height: auto;
    margin: 0 0 15px;
}

.wrapper h1 {
    margin: 0;
}

.wrapper p {
    width: 35%;
    margin: 16px auto 24px;
    text-align: center;
}

.wrapper button {
    border-radius: 50px;
    padding: 8px 24px;
    font-size: 16px;
    cursor: pointer;
    background: #62AD9B;
    color: #fff;
    border: none;
    box-shadow: 0 4px 8px 0 #ccc;
}