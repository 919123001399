/* Landing page start  */

* {
    font-family: Arial;
}


/* for get price icon  */

.right-email {
    position: fixed;
    right: 0;
    top: 250px;
    width: 45px;
    margin-right: 0;
    z-index: 205;
}

#open-popupRight {
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
}


.popupRight {
    position: fixed;
    top: 250px;
    right: -300px;
    width: 300px;
    height: auto;
    background-color: #f0f0f0;
    /* box-shadow: -5px 0 15px rgba(0, 0, 0, 0.5); */
    transition: right 0.3s ease;
}

.popupRight-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#close-popupRight {
    cursor: pointer;
    background-color: transparent;
    color: #000;
    border: none;
    /* padding: 10px 15px; */
    font-size: 16px;
    /* margin-top: 10px; */
    text-align: end;
    width: 100%;
}

.room_description_style{
    font-size: 16px;
    margin-right:120px;
}
@media screen and (max-width: 1008px) {
    .room_description_style{
        font-size: 16px;
        margin-right:0px;
    }
}


#offerEmail {
    border: 0;
    outline: 0;
    padding: 5px 10px;
    margin: 10px 0;
}

.sendPrice {
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    margin: 5px 0;
    padding: 5px;
    font-size: 16px;
}


@media screen and (max-width: 480px) {
    .right-email {
        width: 35px;
    }

    #open-popupRight {
        padding: 5px 10px;
    }
}


.LandinMain {
    padding-bottom: 30px;
}

.alertDiv {
    display: block;
    top: 0;
    position: fixed;
    width: 100%;
}

/* checkin/checkout Start  */

.form-main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 420px;
    flex-direction: column;
    /* border: 2px solid red; */
}

.form {
    
    height: auto;
    text-align: left;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    background: #fff;

    /* border: 2px solid darkblue; */

}

.reservation {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    /* font-family: 'Arial'; */
    font-style: normal;
    font-size: 32px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #1D619C;
    border-radius: 15px 15px 0px 0px;
}

.form_inner {
    display: flex;
    width: 100%;
    justify-content: space-around;
    /* border: 2px solid green; */
}

.form-rsv {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    /* border: 1px solid red; */
}

.main-dates {
    display: block;
    /* border: 2px solid black; */
}


.cal-labl {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 5px;
    /* border: 2px solid red; */
}


.calendarDiv {
    display: flex;
    /* gap: 20px; */
    padding: 10px 0;
}

.rndClndr {
    position: absolute;
    z-index: 2;
}

.checks {
    flex-direction: column;
    height: 210px;
    width: 100%;
    border: 1px solid #b3b3b3;
    background-color: #fff;
    cursor: pointer;
    padding-bottom: 10px;
    align-items: center;
}

.caldiv {
    width: 100%;
    padding: 0 30px;
}

.caldiv .datebtn {
    width: 100%;
    background-color: #9BCFF0;
    border-radius: 10px;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
}

.caldiv .datebtn .date {
    /* border: 1px solid red; */
    font-size: 3rem;
}

.checks label {
    margin-top: 15px;
}

.checks .details {
    width: 180px;
    padding: 22px 0 10px 10px;
    text-align: center;
}

.s-det {
    padding-bottom: 1.6rem;
}

.fill_detail {
    display: block;
    width: 40%;
    /* border-left: 1px solid #b3b3b3; */
    /* border: 2px solid darkblue; */
}

.members {
    display: flex;
    justify-content: center;
    width: auto;
    height: 100%;
}

.members_inner {
    display: flex;
    width: 100%;
    border-left: 1px solid #B3B3B3;
    justify-content: space-evenly;
}

.members_inner .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
}

.details input {
    border: 1px solid #b3b3b3;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
    gap: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.options {
    width: 70px;
    overflow: hidden;
    padding: .5rem 1rem !important;
    border-radius: 4px;
    cursor: pointer;
    background-color: #9BCFF0;
    box-shadow: 0px 1px 0px 0px #519ACB;
    margin-top: 5px;
}

.submit {
    display: block;
    height: auto;
    padding: 5px;
}

.submit button {
    display: block;
    width: 100%;
    height: auto;
    font-size: 20px;
    background: transparent;
    background-color: #1D619C;
    padding: 15px 0;
    color: #fff;
    border: 0;
    border-radius: 0 0 15px 15px;
    /* font-family: Arial; */
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    /* margin: 5px; */
}

.submit button:hover {
    background-color: #519ACB;
}

.submit button.active {
    background-color: #1D619C;
}

/* checkin/checkout end  */

/* Manage booling start  */

.middle-div {
    display: flex;
    width: 100%;
    /* border: 1px solid red; */
    justify-content: center;
    /* padding: 0px 50px; */
    position: relative;
    margin-bottom: 4rem;
}

.mng-booking {
    display: flex;
    align-items: end;
    /* border: 1px solid darkblue; */
    width: auto;
    bottom: 10px;
    left: 0px;
    top: 0px;
    /* font-family: 'Arial'; */
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;
    text-decoration-line: underline;
    color: #FFFFFF;
}


/* why book us start */

.why-book-us {
    display: block;
    border: 10px solid #9BCFF0;
    color: #FFFFFF;
    text-align: center;
    height: auto;
    width: auto;
    border-radius: 0px;
    background-color: #1D619C;
    padding: 10px 20px;
}

@media screen and (max-width: 480px) {
    .why-book-us {
        height: auto;
        padding: 5px;
    }

    .why-land-aminit li {
        font-size: 12px;
        width: 134px;
    }
}

.why-book-us h3 {
    margin: 8px 0;
    font-weight: bold;
}

.why-book-us p {
    margin: 0;
}


.why-land-aminit {
    width: auto;
    display: flex;
    height: auto;
    justify-content: space-evenly;
    gap: 20px;
}

.why-land-aminit ul {
    width: auto;
    display: block;
    /* border: 1px solid black; */
    text-align: start;
}

.why-land-aminit li {
    margin-bottom: 10px;
}


.land-icons {
    margin: 0 8px;
    font-size: 18px;
}

.pop-main {
    display: flex;
    position: relative;
    /* border: 1px solid yellowgreen; */
    padding: 0 10px;
    align-items: flex-end;
}

.popup {
    display: flex;
    /* border: 2px solid black; */
    width: 320px;
    height: 170px;
    background: #F5F5F5;
    box-sizing: border-box;
    box-shadow: inset 0.683751px 1.3675px 15.7263px -2.05125px rgba(0, 0, 0, 0.25);
}

.pop-left {
    width: 35%;
    /* border: 1px solid green; */
    background-color: #519ACB;
}

.pop-right {
    width: 65%;
    /* border: 1px solid red; */
    background-color: #F9D7FF;
}

.popup p {
    margin: 0;
    text-align: center;
}

.pop-btn {
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    background: #FFE261;
    border-width: 0px 2.30639px 2.30639px 0px;
    border-style: solid;
    border-color: #947F98;
    color: #519ACB;
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 700;
}

.text-left {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    /* font-family: AppleGothic; */
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;

}

.fa-free {
    display: inline-block;
    color: #FFDA38;
}

.free {
    font-size: 25px;
    color: #CAA500;
    /* font-family: AppleGothic; */
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;

}

.text-right {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    /* font-family: Arial; */
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #519ACB;
}


/* cards start  */


/* .content {
    display: none;
  } */

.crd-head {
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
    text-transform: capitalize;


    /* background-color: #1D619C; */
    color: #fff;
    /* border-radius: 15px 15px 0 0; */
    align-items: center;
    padding: 15px 0;
}


/* filter start  */

.filters {
    display: flex;
    width: 100%;
    background-color: #1D619C;
    justify-content: space-between;
    height: auto;
    padding: 5px 10px;
    color: #ffff;
    border-radius: 15px 15px 0 0;
}

.inner_filter {
    display: flex;
    height: auto;
    align-items: center;
    gap: 10px;
    width: 33%;
}

.room-last {
    padding: 5px;
    align-items: end;
}

@media screen and (max-width: 480px) {
    .room-last {
        flex-direction: column;
        align-items: start;

    }
}

.roomBtn {
    display: flex;
    gap: 10px;
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    /* display: flex; */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.btn-fc {
    border-radius: 8px;
    background: transparent;
    color: #ffff;
    border: 1px solid #fff;
}

.rgt-flt {
    justify-content: end;
}

.rgt-flt .dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
}


.drp-lst {
    display: none;
}

.btn-fc:hover .drp-lst {
    display: block !important;
}


/* filters end  */

.card_details {
    display: block;
    width: 100%;
    height: auto;
    /* border: 1px solid #7d6e6e; */
    /* border: 2px solid darkcyan; */
    padding: 10px;
    background: #fff;
    padding: 15px;
    /* box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px, rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
    border-radius: 12px; */
    /* margin-bottom:1rem; */
}

/* .card_details:hover {
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px, rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
    transform: translateY(-3px);
} */

.card_inner {
    display: flex;
    width: 100%;
    height: auto;
    /* border: 1px solid blue; */
}

.carousel-inner {
    width: 400px;
    height: 100%;
}

.carousel-item {
    width: 400px;
    height: 230px;
}

.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main_description {
    display: block;
    /* border: 1px solid green; */
    margin: 0 10px;
    border-radius: 12px;
    width: 100%;
}

.room-name {
    display: flex;
    width: auto;
    /* border: 1px solid gray; */
    gap: 12px;
    align-items: center;
}

.last-rooms {
    display: flex;
    align-items: center;
    gap: 5px;
}

.dec-sqr {
    /* font-family: 'Arial'; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 34px;
    color: #000000;
}

.dec-lst {
    display: contents;
    /* font-family: 'Arial'; */
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: -0.005em;
    color: #FF0606;
}

.description {
    display: block;
    width: auto;
    /* border: 1px solid red; */
    padding: 5px;
}

.aminities-block {
    display: flex;
    height: auto;
    /* border: 1px solid black; */
}

.aminities {
    width: auto;
    height: auto;
    /* background: #D5EFFF; */
    padding: 10px;
}

.aminities h5 {
    margin: 8px 0;
    font-weight: bold;
}

.aminities p {
    margin: 0;
}


.aminities .card_inr_icon {
    display: flex;
    gap: 10px;
    align-items: center;
}

.land-aminit {
    width: auto;
    display: flex;
    height: auto;
    justify-content: space-evenly;
    gap: 20px;
}

.land-aminit ul {
    width: 100%;
    display: flex;
    text-align: start;
    list-style-type: none;
    margin: 0;
    padding: 0;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.land-aminit li {
    margin-bottom: 10px;
    display: inline-block;
    width: 204px;
    margin: 5px;
    text-align: start;
}

.room_price {
    display: block;
    width: 25%;
    text-align: end;
    padding: 10px 0;
}

.no-rooms {
    padding: 5px;
    justify-content: space-between;
}

.no-rooms span {
    /* font-family: 'Arial'; */
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: #000000;
}

.room {
    display: flex;
    background: #9BCFF0;
    border-bottom: 1.63227px solid #519ACB;
    border-radius: 8.33021px;
    width: 80px;
}


.card_tab .nav-link {
    color: #000000;
    background: none !important;
}


.card_tab .nav-link:hover {
    background: none !important;
}



.room_card_img {
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}


.room_card_img img {
    width: 228px;
    height: 200px;
}

@media screen and (max-width: 480px) {
    .room_card_img img {
        width: 100%;
    }
}

.soldBtn span {
    font-size: 14px;
}

.room .btn-minus {
    display: inline-block;
    border-radius: 8px 0 0 8px;
    outline: none;
    border: 0;
    background: #9BCFF0;
    width: 33%;
}

.room .btn-total {
    display: inline-block;
    outline: none;
    border: 0;
    background: #9BCFF0;
    /* border-radius: 0 8px 8px 0; */
    width: 33%;
}

.room .btn-plus {
    display: inline-block;
    border-radius: 0 8px 8px 0;
    outline: none;
    border: 0;
    background: #9BCFF0;
    width: 33%;
}

.reser {
    display: block;
    /* border: 1px solid #cbbcbc; */
}

.reser .reserve_btn {
    display: inline-block;
    margin: 7px 0;
    /* border: 1px solid black; */
    border-radius: 5px;
    padding: 5px 10px;
    color: #f5f5f5;
    background-color: rgb(42, 40, 41);
    cursor: pointer;
    box-sizing: border-box;
    width: 145px;
    height: 60px;
    left: 1454px;
    top: 431px;
    background: #1D619C;
    border: 4px solid #FFFFFF;
    border-radius: 38px 0px 0px 38px;
}

.reser .reserve_btn:hover,
.reser .reserve_btn:focus {
    color: #f5f5f5;
    background-color: #519ACB;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;

}

.reser .reserve_btn:hover {
    transform: translateY(-1px);
}

.reser .reserve_btn:active {
    background-color: #1D619C;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}


/* Contact information start  */

#Contact {
    display: block;
    background: #fff;
    border-radius: 28px;
}

.heading {
    display: flex;
    padding-left: 10px;
    /* border: 1px solid red; */
    justify-content: center;
    text-transform: uppercase;
    /* background: #1D619C; */
    color: #ffff;
    border-radius: 24.4841px 24.4841px 0px 0px;
    padding: 20px 0;

}

.contact-main {
    display: flex;
    height: auto;
    padding-bottom: 8px;
    /* width: 100%; */
    /* border: 1px solid #B3B3B3; */
    /* border-radius: 0 0 16px 16px; */
}

.inner-contact-left {
    display: block;
    border-right: 1px solid #B3B3B3;
    width: 70%;
    padding: 2rem 2rem;
}

.code {
    display: block;
    /* border: 1px solid green; */
    padding: 5px 10px px;
    gap: 20px;
    /* padding: 3rem 8rem; */
    /* padding-bottom: 3rem; */
}

.inputBox {
    display: flex;
    position: relative;
    width: 100%;
    /* margin: 0 5px; */
    justify-content: space-between;
    align-items: center;
}

.inputBox input {
    /* position: relative; */
    width: 70%;
    height: 40px;
    padding: 10px;
    /* border: 1px solid red; */
    background: transparent;
    outline: none;
    color: black;
    font-size: 0.80em;
    border: 1px solid rgb(192, 202, 213);
    margin: 0 0 10px 0;
}

.inputBox .country_select {
    width: 70%;
    height: auto;
    /* border: 1px solid red; */
    background: transparent;
    outline: none;
    color: black;
    font-size: 0.80em;
    border: 1px solid rgb(192, 202, 213);
    margin: 0 0 10px 0;
    /* overflow: hidden; */
}


.inputBox .country_select .css-qbdosj-Input {
    margin: 0;
    padding-bottom: 0;
}

@media screen and (max-width:767px) {
    .inputBox .country_select {
        width: 100%;
    }
}

.inputBox .country_select .css-1fdsijx-ValueContainer {
    padding: 0 8px;
    height: 40px;
}

.inputBox .country_select .css-13cymwt-control {
    border: 0;
    height: 38px;
}

/* .inputBox .country_select  */
/* .inputBox .country_select  */
/* .inputBox .country_select  */

.text-span {
    /* width: 91px; */
    left: 120px;
    top: 323px;
    /* font-family: 'Arial'; */
    font-style: normal;
    font-weight: 700;
    /* font-size: 20px; */
    line-height: 25px;
    align-items: center;
    color: #153B5B;
}

.names {
    display: flex;
    /* border: 1px solid goldenrod; */
    width: 70%;
    gap: 10px;
}

.prefix {
    /* border: 1px solid gray; */
    width: 75px;
    font-weight: 400;
}

.prefix .form-control {
    border-radius: 0;
    height: 40px;
}

/* .bg {
    background: #FFFFFF;
    border: 0.67463px solid #519ACB;
    box-shadow: 1.34926px 1.34926px 4.04778px #519ACB;
} */

#FullName {
    width: 100%;
}

.name-input {
    display: flex;
    width: 100%;
    /* border: 1px solid green; */
    gap: 10px;
}


.content_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    color: black;
    height: auto;
}

.form-country {
    background-color: transparent !important;
    background: transparent;
    border-radius: 0;
    border: 1px solid rgb(192, 202, 213);
    cursor: pointer;
    width: 70%;
}

.inputBox-city {
    width: auto;
    margin: 0;
}

.inputBox-city input {
    height: 38px;
}

.content_inner textarea {
    width: 70%;
    padding: 5px;
    color: black;
    border: 0;
    border: 1px solid rgb(192, 202, 213);
}


.content_inner .promo_btn_div {
    /* border: 2px solid; */
    width: 70%;
    display: flex;
    gap: 10px;
}

.content_inner .promo_btn_div input {
    width: 100%;
    padding: 10px;
    /* border: 1px solid red; */
    background: transparent;
    outline: none;
    color: black;
    font-size: 0.80em;
    border: 1px solid rgb(192, 202, 213);
    /* margin: 0 0 10px 0; */

}

.content_inner .promo_btn_div button {
    font-size: 14px;
    background: #1D619C;

}

@media screen and (max-width: 480px) {
    .content_inner .promo_btn_div {
        width: 100%;
    }
}


.phone-input-field .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    width: auto;
    border: 1px solid rgb(192, 202, 213);
    height: 40px;
    padding: 0 12px;
}

.PhoneInput {
    display: flex;
    align-items: center;
    gap: 5px;
}


.button_s {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
    /* border: 2px solid; */
}

.button_s_p {
    text-align: end;
    font-size: 13px;
    color: #947F98;
    font-style: italic;
    font-weight: 700;
}

.submitbtn {

    display: flex;
    border: 1px solid rgb(192, 202, 213);
    border-radius: 10px;
    /* margin-top: 10px; */
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    align-self: end;
    color: #519ACB;
    text-decoration: none;
    background: #F3FAFF;
    width: 70%;
    justify-content: space-between;
}


@media screen and (max-width:480px) {
    .submitbtn {
        width: 100%;
    }

    .button_s_p {
        text-align: start;
    }
}


.submitbtn:hover,
.submitbtn:focus {
    color: #fff;
    background-color: #519ACB;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;

}

.submitbtn:hover {
    transform: translateY(-1px);
}

.submitbtn:active {
    background-color: #1D619C;
    border-color: rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: #fff;
    transform: translateY(0);
}

.inner-contact-right {
    display: block;
    /* border: 1px solid darkblue; */
    width: 30%;
    padding: 0 20px;
}

/* .text-center {
    color: #1D619C;
    text-decoration: underline;
} */

.cust-detail {
    display: block;
    margin-bottom: 5px;
    /* border: 1px solid green; */
}


.cust-inner {
    display: flex;
    justify-content: space-between;
    padding: 1px 0 2px 20px;
}

.cust-inner div {
    display: inline-block;
    width: 50%;
    max-width: 50%;
    /* border: 1px solid darkblue; */
}

.cust-inner .left-span {
    /* font-family: 'Arial'; */
    font-style: normal;
    font-weight: 500;
    color: #000000;
    padding: 5px 0;
}

.cust-inner .right-span {
    /* font-family: 'Arial'; */
    font-style: normal;
    font-weight: 550;
    line-height: 27px;
    align-items: center;
    color: #153B5B;
}


.sub-price {
    border-top: 1px solid #9BCFF0;
    border-bottom: 1px solid #9BCFF0;
}


#Final_price {
    font-style: normal;
    line-height: 27px;
    align-items: center;
    color: #153B5B;
}


#Final_tax {
    font-style: normal;
    line-height: 27px;
    align-items: center;
    color: #153B5B;
}

/* final booking button start  */

.bookingbtn {
    display: block;
    text-align: center;
    padding: 5px;
}

.cmplt {
    border: 1px solid rgb(192, 202, 213);
    display: inline-block;
    padding: 15px;
    cursor: pointer;
    background: #153B5B;
    color: #fff;
    border-radius: 0 0 25px 25px;
    width: 100%;
}

.cmplt:hover,
.cmplt:focus {
    color: #fff;
    background-color: #4784ad;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;

}

.cmplt:hover {
    transform: translateY(-1px);
}

.cmplt:active {
    background-color: #1D619C;
    border-color: rgb(192, 202, 213);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: #fff;
    transform: translateY(0);
}


/* Footer start  */



@media screen and (max-width: 480px) {



    .form {
        width: 100%;
        margin: 4rem 0;
    }

    .form-main {
        height: auto;
    }

    .form_inner {
        flex-direction: column;
    }

    .form-rsv {

        width: 100%;

    }

    .cal-labl {
        padding: 12px 0;
    }

    .caldiv {
        padding: 0 12px;
    }

    .calendarDiv {
        padding-bottom: 20px;
    }

    .fill_detail {
        width: 100%;
        height: 135px;
        border-top: 1px solid #b3b3b3;
    }

    .card_inner {
        flex-direction: column;
        padding-bottom: 20px;
    }

    .carousel-inner {
        width: auto;
    }

    .aminities-block {
        flex-direction: column;
    }


    .dec-lst {
        font-size: 10px;
        letter-spacing: 0;
    }

    .room_price {
        width: auto;
        /* padding-top: 10px; */
        padding: 10px 10px 0px 0;
    }

    .inner-contact-left {
        width: 100%;
        padding: 2rem 1rem;
        border-bottom: 1px solid #B3B3B3;
    }

    .reser .reserve_btn {
        width: 120px;
        height: 50px;
    }


    .contact-main {
        flex-direction: column;
    }

    .inputBox {
        align-items: start;
        flex-direction: column;
        row-gap: 8px;
    }

    .inputBox input {
        width: 100%;
    }

    .content_inner {
        flex-direction: column;
        align-items: start;
        height: auto;
        row-gap: 8px;
    }

    .names {
        width: 100%;
    }

    .form-country {
        width: 100%;
    }

    .content_inner textarea {
        width: 100%;
    }


    .inner-contact-right {
        width: auto;
        padding: 10px;
    }

    .cmplt {
        width: 100%;
    }



    .rndClndr {
        width: 85%;
    }

    .calright {
        left: 25px;
    }

    .submit button {
        font-size: 17px;
        /* line-height: 15px; */
    }


    .inner_filter {
        display: none;
    }

    .filters {
        justify-content: center;
        padding: 0;
    }

    .main_description {
        margin: 0;
        padding: 10px;
    }

    .card_details {
        padding: 0;
    }

    .aminities {
        padding: 8px 5px;
    }

    .land-aminit li {
        width: 132px;
        font-size: 14px;
    }
}


@media screen and (max-width: 768px) and (min-width: 481px) {

    .form {
        width: 100%;
    }

    .card_inner {
        flex-direction: column;
    }

    .carousel-inner {
        width: 100%;
    }

    .carousel-item {
        width: 100%;
    }

    .room-last {
        padding: 10px 10px 10px 0;
    }

    .land-aminit li {
        width: 150px;
    }

    .inner_filter {
        align-items: center;
        width: auto;
    }

    .btn-fc {
        width: auto;
        font-size: 12px;
    }

    .room_price {
        display: flex;
        width: 25%;
        text-align: end;
        flex-direction: column;
        align-items: end;
        justify-content: end;
        padding: 0px 10px 0px 0;
    }

    .no-rooms span {
        font-size: 16px;
        line-height: 30px;
    }

    .no-rooms {
        padding: 5px 0;
        gap: 5px;
    }

    .contact-main {
        flex-direction: column-reverse;
    }

    .inner-contact-left {
        width: 100%;
        padding: 2rem 3rem;
        border-top: 1px solid #B3B3B3;
        border-right: 0;
    }

    .inner-contact-right {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {

    .form {
        width: 100%;
    }

    .card_inner {
        flex-direction: column;
    }

    .carousel-inner {
        width: 100%;
    }

    .carousel-item {
        width: 100%;
    }

    .room-last {
        padding: 10px 10px 10px 0;

    }

    .land-aminit li {
        width: 150px;
    }

    .inner_filter {
        align-items: center;
        width: auto;
    }

    .btn-fc {
        width: auto;
        font-size: 12px;
    }

    .room_price {
        display: flex;
        width: 25%;
        text-align: end;
        flex-direction: column;
        align-items: end;
        justify-content: end;
        padding: 0px 10px 0px 0;
    }

    .no-rooms span {
        font-size: 16px;
        line-height: 30px;
    }

    .no-rooms {
        padding: 5px 0;
        gap: 5px;
    }

    .contact-main {
        flex-direction: column-reverse;
    }

    .inner-contact-left {
        width: 100%;
        padding: 2rem 3rem;
        border-top: 1px solid #B3B3B3;
        border-right: 0;
    }

    .inner-contact-right {
        width: 100%;
    }
}


/* Meal plan component start  */

.mealplanDiv {
    margin-top: 20px;
}

.plansDiv {
    display: block;
    padding: 20px;
    background-color: #fff;

}

.plnansheading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgb(10, 58, 117);
    color: #fff;
    padding: 15px;
    border-radius: 24.4841px 24.4841px 0px 0px;
}

.plansinr {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.plansinr .form-check-input {
    border: 1px solid;
}



@media screen and (max-width:480px) {
    .plansinr span {
        font-size: 14px;
    }
}

.plnshead {
    font-weight: 600;
}

.plansDiv .table {
    display: flex;
}

.plansDiv .table thead tr {
    display: flex;
    flex-direction: column;
}

.plansDiv .table tbody {
    display: flex;
    /* flex-direction: column; */
}

.plansDiv .table tbody tr {
    display: flex;
    flex-direction: column;
}



/* Meal plan component end  */
.querycontainer{
    width:40%;
}

@media screen and (max-width:1500px) {
    .querycontainer{
        width:60%;
    }
}

@media screen and (max-width:992px) {
    .querycontainer{
        width:80%;
    }
}

@media screen and (max-width:572px) {
    .querycontainer{
        width:100%;
    }
}
