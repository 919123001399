/* Style for the phone input container */
.phone-input-container {

    width: 70%;
}

.phone-input-field .PhoneInputCountr {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    width: auto;
    border: 1px solid rgb(192, 202, 213);
    height: 40px;
    padding: 0 12px;
}

/* Style for the input field */
.phone-input-field {

    width: 100%;

}

@media screen and (max-width: 480px) {

    .phone-input-container {

        width: 100%;
    }
}