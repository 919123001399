*,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


li {
    text-decoration-style: none;
    list-style: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.loginbutton {
    padding: 5px 20px;
}

li a {
    text-decoration: none;
    color: aliceblue;
}

footer h1 {
    margin-top: 20px;
    padding: 0;
    font-weight: 600;
    border-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;

    border: 0 !important;
}


nav {
    display: flex;
    height: auto;
    background: #153B5B;
    color: rgb(234, 234, 226) !important;

}

.navbar {
    height: 65px;
    color: rgb(234, 234, 226) !important;
}

.navbar button:focus:not(:focus-visible) {
    outline: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

/* for navbaar logo  */

.nav-logo {
    display: inline-block;
    /* border: 1px solid white; */
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    margin: 0 10px;
    overflow: hidden;
}

.nav-logo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.collapse {
    justify-content: end !important;
}

.H-name {
    color: #fff;
    font-size: 25px;
}

.nav-link {
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    width: auto;
    text-align: center;
    /* text-decoration: underline; */
}

.nav-icon {
    text-shadow: none;
}

.nav-link:hover {
    background: #519ACB;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;

}

.nav-link:focus,
.nav-link:hover {
    color: #fff;
    background-color: #1D619C;
}

.nav-item {
    display: flex;
    padding: 10px 0;
    width: auto;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
}

/* For curreny Btn  */

#currency {
    background: transparent;
    border: 0;
    color: #fff;
    outline: 0;
}


.nav-item .dropdown .btn {
    background: transparent;
    border: 0;
    color: #fff;
    outline: 0;
    width: 100%;
    display: flex;
}

.login {
    border: 1px solid #927345d7;
    width: auto;
    height: auto;
    padding: 20px 20px 0;
}

#loginForm div {
    display: block;
    width: 100%;
}


.footer-login {
    display: block;
    margin-top: 10px;
    text-align: end;
    font-size: 12px;
    border: 2px solid red;
}


.ls-header {
    justify-content: center;
    padding: 5px 0 10px 0;
    border-width: 6.7463px 6.7463px 0px 6.7463px;
    border-style: solid;
    border-color: #FFFFFF;
}

.cn-btn {
    display: flex;
    width: 100%;
    justify-content: end;
    padding-right: 5px;
    position: absolute;
}

.btn-close {
    margin: 15px;
}


.btn-close:hover {
    background-color: white !important;
}


.nav-body input {
    font-size: 12px;
    padding-left: 10px;
    font-style: italic;
    height: 2rem;
}

.nav-submit {
    display: block;
    width: 100%;
    height: auto;
    font-size: 20px;
    background-color: #1D619C;
    padding: 8px 0;
    color: #fff;
    border: 0;
    border-radius: 0 0 15px 15px;
    /* font-family: Arial; */
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin: 10px 0;
}

.nav-submit:hover,
.nav-submit:focus {
    background-color: #f5eaeaad;
}

.nav-foot {
    padding: 0 15px;
}


/* Footer start  */

footer {
    display: block;
    background: #153B5B;
    /* background: #283345; */
    /* background: #434343; */
    /* background: #262626; */
    color: #f5f5f5;
    height: auto;
    padding: 50px 0 15px 0;
    list-style: none;
    font-size: 14px;
}



.footsocialicon:hover {
    color: #ac7272d5;
}

.footer-outer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* display: flex; */
    /* padding: 50px 0; */
}


@media screen and (max-width:480px) {
    .footer-outer {
        grid-template-columns: repeat(1, 1fr);
    }

    .FootAddrs {
        padding: 10px 0;
    }
}


@media screen and (max-width:768px) and (min-width:480px) {
    .footer-outer {
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 5px;
    }
}


.footLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    /* border: 1px solid white; */
    border-radius: 50%;
    overflow: auto;
}

.footLogo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footHeading {
    font-size: 18px;
}

.foot_policies {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.foot_policies ul li {
    margin-top: 10px;
}


.footeuseful_links {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 50px;
}

.foot_policies a:hover {
    text-decoration: underline;
}

.Contact-item {
    margin-top: 10px;
    line-height: 2;
}

.map {
    display: block;
    height: 200px;
}

.map iframe {
    width: 100%;
    height: 100%;
}


.footer-main ul {
    line-height: 2;
    padding: 0;
}

footer a {
    color: #CACACA;
    font-size: 15px;
    line-height: 2;
}


footer a:hover {
    color: #ac7272d5;
    text-decoration: underline;
}

.foot-icons {
    margin-right: 5px;
}

.copyright {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 5px;
    flex-wrap: wrap;
    border-top: 1px solid aliceblue;
    align-items: center;
}

/* .copyright-inner {
    display: block;
    width: 50%;
}

.copy-right {
    text-align: end;
} */


.social-media {
    display: block;
    padding: 10px 0;
    /* padding-left: 5px; */
}

.social-media .fa-social {
    font-size: 20px;
    color: #CACACA;
}

.Social_foot_icon {
    display: flex;
    gap: 10px;
    margin-top: 5px;
    margin-left: -5px;
}


.foot-modal {
    --bs-modal-width: 800px;
}

.foot-header h1 {
    padding: 0;
}



@media screen and (max-width:480px) {

    .navbar {
        z-index: 3;
        height: auto;
    }

    .nav-item {
        padding: 10px 0 0 0;
    }

    .nav-link {
        width: 100%;
    }

    .navbar-brand {
        padding: 5px 16px;
    }

    .nav-item .dropdown .btn {
        width: 100%;
    }

    footer {
        padding-left: 10px;
    }

    .footer-main {
        width: auto;
    }

    .footer-outer {
        padding: 0;
        gap: 20px;
    }

    .footeuseful_links {
        padding-left: 15px;
    }


    .map iframe {
        width: 100%;
        height: 100%;
    }


    .inner_conditions a {
        font-size: 9px;
        margin: 0 1px;
    }

    .social-media {
        padding: 0;
    }


    .inner_conditions {
        margin: 10px 0;
    }

    .ls-content {
        margin-top: 20%;
    }


    .copyright {
        flex-direction: column;
        margin-top: 10px;
    }

    .copyright-inner {
        width: 100%;
        text-align: center;
        font-size: 14px;
    }

    .copy-right {
        text-align: center;
    }

    .Social_foot_icon {
        margin-left: -5px;
    }

    /* .foot-icons{
        margin-left: 0;
    } */
}


@media screen and (max-width:768px) and (min-width:481px) {

    .navbar {
        z-index: 3;
        height: auto;
    }

    .Social_foot_icon {
        margin-left: -5px;
    }

    .nav-link {
        width: 100%;
    }


    .footAdd {
        margin-bottom: 20px;
    }

    .map {
        display: block;
        margin-left: 0;
        height: 170px;
        /* margin-top: 0px; */
    }

    .map iframe {
        width: 100%;
        height: 100%;
    }




    .inner_conditions a {
        font-size: 15px;
        margin: 0 0 5px 0;
    }



    .social-media {
        padding: 0;
    }

    .social-media .fa-social {
        font-size: 25px;
        margin-right: 8px;
    }

    .inner_conditions {
        margin: 10px 0;
    }
}

@media screen and (max-width:1024px) and (min-width:769px) {


    .inner_conditions a {
        font-size: 13px;
    }
}

.whatsapp_float {
    position: fixed;
    bottom: 40px;
    right: 20px;
}

.whatsapp_float_btn {
    width: 50px;
}

.call_float {
    position: fixed;
    bottom: 40px;
    left: 20px;
}

.call_float_btn {
    width: 50px;
}