.loginPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div {
    padding: 10px;
    justify-content: center;
    display: flex;
    max-width: 400px;
    width: 100%;
    margin: auto;
    flex-direction: column;
    font-size: 14px;
    background-color: #fff;
    border-radius: 10px;
}

.div-2 {
    text-align: center;
    color: #525252;
    width: 100%;
    font: 700 30px Nunito Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-3 {
    text-align: center;
    color: #525252;
    width: 100%;
    font: 400 14px Nunito Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-4 {
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border-color: rgba(232, 232, 232, 1);
    border-style: solid;
    border-width: 1px;
    display: flex;
    margin-top: 32px;
    width: 100%;
    color: var(--Gray-3, #828282);
    font-weight: 700;
    padding: 10px;
}

.div-5 {
    display: flex;
    gap: 13px;
}

.img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 25px;
}

.div-6 {
    font-family: Nunito Sans, sans-serif;
    margin: auto 0;
}

.div-7 {
    color: #a1a1a1;
    align-self: center;
    margin-top: 36px;
    font: 600 12px Nunito Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-8 {
    color: var(--Gray-3, #828282);
    font-family: Nunito Sans, sans-serif;
    font-weight: 600;
    margin-top: 36px;
    width: 100%;
}

.div-9 {
    font-family: Nunito Sans, sans-serif;
    align-items: start;
    border-radius: 5px;
    border-color: rgba(222, 210, 217, 1);
    border-style: solid;
    border-width: 1px;
    margin-top: 4px;
    width: 100%;
    /* color: var(--Gray-5, #828282); */
    font-weight: 400;
    white-space: nowrap;
    justify-content: center;
    padding: 13px 10px;
}

.div-10 {
    color: var(--Gray-3, #828282);
    font-family: Nunito Sans, sans-serif;
    font-weight: 600;
    margin-top: 24px;
    width: 100%;
}

.div-11 {
    align-items: start;
    border-radius: 5px;
    border-color: rgba(222, 210, 217, 1);
    border-style: solid;
    border-width: 1px;
    margin-top: 4px;
    width: 100%;
    color: var(--Gray-5, #e0e0e0);
    white-space: nowrap;
    padding: 16px 10px 13px;
    font: 400 10px Nunito Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-12 {
    display: flex;
    margin-top: 8px;
    width: 100%;
    padding-right: 20px;
    gap: 20px;
    font-size: 12px;
    justify-content: space-between;
}

.div-13 {
    justify-content: center;
    display: flex;
    gap: 8px;
    color: #a1a1a1;
    font-weight: 400;
}

.img-2 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 12px;
    margin: auto 0;
}

.div-14 {
    font-family: Nunito Sans, sans-serif;
}

.div-15 {
    color: #7f265b;
    font-family: Nunito Sans, sans-serif;
    font-weight: 600;
}

.div-16 {
    border: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #7f265b;
    margin-top: 32px;
    width: 100%;
    color: #fff;
    white-space: nowrap;
    padding: 13px 10px 12px;
    font: 700 16px Nunito Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
}


.out {
    outline: none;
}


@media screen and (max-width: 768px) {

    .div-3 {
        text-align: center;
        color: #525252;
        width: 100%;
        font: 400 14px Nunito Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
    }

    .div-2 {
        text-align: center;
        color: #525252;
        width: 100%;
        font: 700 26px Nunito Sans, -apple-system, Roboto, Helvetica,
            sans-serif;
    }

    .div {
        justify-content: center;
        display: flex;
        margin: auto;
        width: 100%;
        flex-direction: column;
        font-size: 14px;
    }
}