.main_success{
    
    background-color: #fff;
    width: 100%;
    /* height: 100vh; */
    /* border: 2px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
}


.succespage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 100vh; */
    color: green;
    padding: 50px;
    width: 600px;
    /* border: 1px solid; */
    box-shadow: 2px 2px 12px 12px rgba(60, 57, 57, 0.175);
}

.succuss_MDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.succuss_detailDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}



.succesDataTable {
    width: 100%;
}

.succespage h6 {
   margin: 10px 0;
}


@media screen and (max-width: 480px) { 
    .succespage {
        padding: 5px 18px;
        width: auto;
        box-shadow: none;
    }
}