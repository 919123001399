*,
body {
  margin: 0;
  padding: 0;
  font-family: 'AppleGothic';
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p {
  /* font-size: 1.1rem; */
  padding: 0;
  margin: 0;
  text-align: justify;
}

a {
  font-size: 1.1rem;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ReserveButtonForPayment{
  display: inline-block;
    border: 2px solid rgb(192, 202, 213);
    border-radius: 0px 0px 15px 15px;
    /* margin-top: 10px; */
    padding: 15px;
    font-size: 17px;
    font-weight: 600;
    align-self: end;
    color: white;
    text-decoration: none;
    background: #1D619C;
    width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* custom css start  */



/* Navbar start  */


/* Navbar End  */

/* Add this CSS to your project's stylesheets or use a CSS-in-JS solution */